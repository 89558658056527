@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #f1f1fd;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.custome-padding-row .ant-table-cell {
  padding: 6.5px 5px !important;
}
.custome-dashboard-table .ant-table-thead .ant-table-cell {
  padding: 6px 5px !important;
}
.avatar-uploader .ant-upload {
  width: 150px !important;
  height: 150px !important;
  margin: auto;
}
.ant-modal-confirm-btns .ant-btn-primary {
  background-color: #722f38 !important;
}
.ant-modal-confirm-btns .ant-btn-default {
  color: #722f38 !important;
  border-color: #722f38 !important;
}
.ant-tabs-tab {
  border: 1px solid #868585 !important;
}
.ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: none !important;
}
.banner-uploader .ant-upload {
  width: 100% !important;
  height: 200px !important;
  margin: auto;
}
.userSelect .ant-select-selector .ant-select-selection-item-content {
  padding-left: 38px !important;
}
.userSelect .ant-select-selector .ant-avatar {
  top: 2px !important;
  left: 8px !important;
}
.userSelect .ant-select-selection-item {
  height: 46px !important;
}
.ant-table-thead .ant-table-cell {
  font-family: "Roboto-Regular" !important;
  font-size: 15px !important;
}
.ant-btn.bg-primary-brown {
  font-family: "Roboto-Regular" !important;
  font-size: 16px !important;
}
.ant-tabs-tab-btn {
  font-size: 24px;
  font-family: "Roboto-Regular" !important;
}
.loginBanner .ant-upload {
  background-color: transparent !important;
  border: none !important;
  height: 19px !important;
  width: 70px !important;
}
.custom-model-class .ant-modal-content {
  background-color: #f1f1fd !important;
}

/* styles.css */
.modal-container {
  border-top: 2px solid #E6E6E6;
  border-bottom: 2px solid #E6E6E6;
  padding: 16px;
}

.modal-content {
  border: 2px solid #E6E6E6;
  padding: 16px;
  height: 100%; /* Ensures the content fills the modal body */
  overflow-y: auto; /* Enables scrolling within the content area */
  margin-left: -16px;
  margin-right: -16px;

}
.image-container {
  display: block;
  margin: 0 auto; /* Center the image horizontally */
  width: 100px;
  height: 100px;
  border-radius: 6px;
  border: 1px solid; /* Assuming you want a border */
  object-fit: contain; /* Ensure the image is fully visible within the container */
  object-position: center; /* Center the image within the container */
  margin-right: 10px;
}